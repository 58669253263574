import './App.scss';
import logo from './images/logo.svg'
import designImage from './images/design_image.png'
import playSvg from './images/play.svg'
import demoLogo from './images/demo_logo.svg'
import starSvg from './images/star.svg'
import cubeSvg from './images/cube.svg'
import octaSvg from './images/octo.svg'
import Members from "./Members/Members";

function App() {
    return (
        <div className="App">
            <div className='logoAndInfoWrapper'>
                <div>
                    <img src={logo} alt=""/>
                    <div className='title'>
                        Democratizing <br/>
                        Drone Automation
                    </div>
                    <div className='description'>
                        No more coding or spending hours learning
                        how to fly a drone. <br/>
                        Just drag and
                        drop pre-built blocks and AUTOMATE
                        your drone operations.
                    </div>

                    <div className='buttonsWrapper'>
                        <button className='getStartedBtn'>
                            Get Started
                        </button>
                        <button className='watchTheDemoBtn'>
                            <img src={playSvg} alt=""/>
                            Watch the demo
                        </button>
                    </div>
                </div>
                <img className='designLogo' src={designImage} alt=''/>
            </div>

            <div className='ourProductWrapper'>
                <img src={demoLogo} className='demoLogo' alt=""/>
                <div className='productInfo'>
                    <div className='headerLabelTag'>OUR PRODUCT</div>
                    <div className='productHeader'>why choose DroneHub?</div>
                    <div className='productDescription'>
                        <p> Imagine being a farmer and having to protect your crops from pesky birds and insects. It's a tough job, right? But what if I told you that we have a solution that makes it easy and fun? <br/></p>
                        <p> Our software makes it simple for farmers to use drones to scare away those pests and protect their crops. No more struggling with complicated drone controls or hiring expensive experts. With our software, farmers can focus on other important tasks while the drone takes care of pest control. <br/></p>
                        <p> And the best part? Our software is so easy to use that even a kid can do it! </p>
                    </div>
                </div>
            </div>

            <div>
                <div className='productInfo'>
                    <div className='headerLabelTag'>Features</div>
                    <div className='productHeader'>what we offer</div>
                    <div className='productDescription' style={{marginTop : 9}}>
                        Say goodbye to complex coding and hello to effortless automation. <br/>
                        Our drag-and-drop software makes drone operations a breeze. Take your drone <br/>
                        operations to the next level with our automation software that anyone can use
                    </div>
                </div>
            </div>

            <div className='offersWrapper'>
                <div>
                    <div className='offerHeader'>
                        <img src={starSvg} alt=""/>
                        <div>saas license based</div>
                    </div>
                    <div className='offerDescription'>
                        We offer a monthly subscription to our software solution for drone automation, which includes access depending on the features chosen and customer support
                    </div>
                </div>
                <div>

                    <div className='offerHeader'>
                        <img src={cubeSvg} alt=""/>
                        <div>developer license</div>
                    </div>
                    <div className='offerDescription'>
                        We also offer developers licenses for our software development tools and platform, allowing us to generate revenue and increase adoption among the developer community
                    </div>

                </div>

                <div>
                    <div className='offerHeader'>
                        <img src={octaSvg} alt=""/>
                        <div>Custom feature creation</div>
                    </div>
                    <div className='offerDescription'>
                        We also offer a custom feature creation service for customers who require unique features on integrations specific to their business needs
                    </div>
                </div>
            </div>


            <div style={{marginTop : 236}}>
                <div className='headerLabelTag' style={{textAlign : 'center'}}>Our team</div>
                <div className='productHeader' style={{textAlign : 'center', marginTop : 14, width : 'unset'}}>Meet our team members</div>
                <div className='productDescription' style={{textAlign : 'center', marginTop : 24, height : "unset", marginBottom : '64px'}}>Meet out awesome team! With two skilled engineers and a data-driven product manager leading the way, we are passionate about <br/>
                    creating cutting-edge solutions. And to top it off, one of our engineers has hands-on experience building the drone hardware itself! <br/>
                    Together, we are driving innovation in drone automation technology
                </div>
            </div>

            <div className={'teamMembers'}>

                <Members/>

            </div>

        </div>
    );
}

export default App;
