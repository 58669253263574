import React from 'react';
import vazgenAv from '../images/vazgen.png'
import lucyAv from '../images/lucy.png'
import haykAv from '../images/hayk.png'
import roubenAv from '../images/rouben.png'
import robertAv from '../images/robert.png'

import TeamMember from "./TeamMember";
import './TeamMember.scss'


const membersLine1 = [
    {
        name     : "Vazgen Barfyan",
        position : "Software engineer",
        img      : vazgenAv
    },
    {
        name     : "Lucy Shahnazaryan",
        position : "Tech Product Manager",
        img      : lucyAv
    },
    {
        name     : "Hayk Hayrapetyan",
        position : "Software developer",
        img      : haykAv
    }]

const membersLine2 = [
    {
        name     : "Robert Bassili",
        position : "Mentor",
        img      : robertAv
    },
    {
        name     : "Rouben Meschian",
        position : "Mentor",
        img      : roubenAv
    }
]
const Members = () => {
    return (
        <div>
            <div className='membersInfoWrapper'>
                {membersLine1.map((info, index) => <TeamMember key={index} {...info}/>)}
            </div>
            <div className='membersInfoWrapper'>
                {membersLine2.map((info, index) => <TeamMember key={index} {...info}/>)}
            </div>

        </div>
    );
};

export default Members;
