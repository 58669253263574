import React from 'react';
import './TeamMember.scss'

const TeamMember = ({name, position, img}) => {
    return (
        <div className='memberInfoWrapper'>
            <img className={"memberImage"} src={img} alt=""/>
            <div className={'memberName'}>
                {name}
            </div>
            <div className={'memberPosition'}>
                {position}
            </div>
        </div>
    );
};

export default TeamMember;
